@import url("https://use.typekit.net/zzr4enm.css");

$sigFont:filson-soft, sans-serif;
$signatureFont: $sigFont;
$sigFontWide:filson-soft, sans-serif;
$deco:filson-soft, sans-serif;
$white: #fff;
$black: #000;
// $red: #a32135;
$red:  #000;
$yellow:  #ffc904;
$pink:  $red;
$darkRed: #6f1624;
$orange: #ed1e25;
$darkOrange: #86090d;
// $background-color: #fffaec;
$background-color: #fff;
$purpleRadio: radial-gradient(circle, rgba(106,0,161,0.8925945378151261) 25%, rgba(156,4,95,0.7301295518207283) 100%);

$max-site-width: 840px;
