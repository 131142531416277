

@import './_vars.scss';

html, body {
  background:  black !important;
}


.pablitosworld {
  background-color: black;
  // background-color: #f9f9f9;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='18' viewBox='0 0 100 18'%3E%3Cpath fill='%23ececec' fill-opacity='0.4' d='M61.82 18c3.47-1.45 6.86-3.78 11.3-7.34C78 6.76 80.34 5.1 83.87 3.42 88.56 1.16 93.75 0 100 0v6.16C98.76 6.05 97.43 6 96 6c-9.59 0-14.23 2.23-23.13 9.34-1.28 1.03-2.39 1.9-3.4 2.66h-7.65zm-23.64 0H22.52c-1-.76-2.1-1.63-3.4-2.66C11.57 9.3 7.08 6.78 0 6.16V0c6.25 0 11.44 1.16 16.14 3.42 3.53 1.7 5.87 3.35 10.73 7.24 4.45 3.56 7.84 5.9 11.31 7.34zM61.82 0h7.66a39.57 39.57 0 0 1-7.34 4.58C57.44 6.84 52.25 8 46 8S34.56 6.84 29.86 4.58A39.57 39.57 0 0 1 22.52 0h15.66C41.65 1.44 45.21 2 50 2c4.8 0 8.35-.56 11.82-2z'%3E%3C/path%3E%3C/svg%3E");
  .page {
    min-height: 100vh;
  }
  .page-header {
    max-width: $max-site-width;
    margin: 1em auto;
    padding-left: 0.2em;
    padding-bottom: 0;
    h1 {
      text-transform: uppercase;
      font-weight: 600;
      font-family: $sigFont;
      font-size: 1.5em;
      color: $red;
    }
    // border-bottom: 2px solid $red;
    display: flex;
    align-items: center;
    .text {
      flex: 1;
    }
    .logo-wrapper {
      width: 100px;
      height: 98px;
      // justify-self: flex-end;
      margin: 0;
      align-self: flex-end;
      svg {
        // border-bottom: 2px solid black;
        display: inline-block;
        margin: 0;
        padding: 0;
      }
    }
  }
  .product-link {
    background: $red;
    border: none;
    color: $white;
    display: flex;
    font-weight: 400;
    align-items: center;
    .plus {
      font-weight: 200;
      position: relative;
      top: 0.05em;
      font-size: 2em;
      line-height: 0.3em;
    }
    &:visited {
      color: $white;
    }
  }
  font-family: $signatureFont;
  background-color: $background-color;
  svg.nav-bar-logo {
    display: block;
    position: relative;
    height: 35px;
    // margin-top: -0.4em;
    // bottom: -0.6em;
    // padding-top: 0.23em;
    padding-left: 0.3em;
    // padding-bottom: 0.023em;
  }
  .nav-bar-logo {

    // background: blue;
    height: 50px;
    padding-bottom: 0;
    padding: 0.4em;
    display: block;
    // margin-bottom: -0.2em;

    // margin-left: 0.3em;
  }
  .tool-bar {
    padding-top: 0.35em;
    // display: none;

  }

  .cart.minimized {
    // background: rgba(237,237,237, 0.8);
    // display: none;
    // background: rgb(191,81,1);
    background: #ed1e25;
// background: linear-gradient(0deg, rgba(191,81,1,1) 0%, rgba(255,174,0,1) 100%);
background: linear-gradient(0deg, #ed1e25 0%, rgba(255,174,0,1) 100%);
    padding-left: 0;
    padding-top: 0;
    background-color: $red;
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }

  a.checkout-button.button {
    background: $pink;
    text-transform: capitalize;
    box-shadow: none;
    border: none;
    color: $white;
    font-weight: 600;
  }

  .category__header {
    text-align: left;
    max-width: 840px;
    margin: 0 auto;

    border-bottom: 1.5px solid black;
    // padding: 0 0.5em;
    /* background: rgba(237,237,237, 0.4); */

  }
  .category-name {
    margin-left: 0em;
    padding-left: 0.2em;
  }
  .category__description {
    text-align: left;
    margin-left: 0em;
    padding-left: 0.2em;
    padding-bottom: 0.5em;
    max-width: none;
  }

  .category-name .text {
    font-family: $deco;
    font-weight: 600;
    text-transform: lowercase;
    // text-decoration: underline;
    // text-decoration-thickness: 2px;
    font-size: 1.5em;
    padding: 0;
    background: none;
    color: black;

  }

  .menu-category-list-view {
    .photos.grid {

      justify-content: space-between;
    }
    .menu-item {
      background: none;
      // align-items: center;
      .orignal-price {
        // font-weight: 800;
        display: inline-block;
        padding: 0.2em;
        font-size: 1.1em;
        // display: none;
      }
      .photo-container {
        width: 300px;
      }
      .food-name {
        font-family: $deco;
        text-transform: lowercase;

        font-weight: 600;
        line-height: 1.5em;

      }
      .menu-item__description {
        font-family: $sigFont;
        font-weight: 400;
      }
      @media (min-width: 550px) {
        width: 49%
      }

    }

    .content {
      flex: auto;
    }


    .price {
      // color: $red;
      // &::before {
      //   content: "..."
      // }
    }

    a.product-link {
      // align-self: center;
      margin-right: 0.9em;
      font-size: 0.8em;

    }
  }



  .price-summary {
    padding-bottom: 5em;
  }
  //
  //
  .pages-nav {
    font-family: $deco;
    background: $pink;
    font-weight: 600;
    text-transform: lowercase;

    ul {
      display: flex;
      flex-wrap: wrap;
    }
    @media screen and (max-width: 480px) {
      font-size: 4vw;
    }
  }

  a.page-link {
    $borderWidth: 3px;
    // padding-top: 0.5em;
    $paddingY: 0.35em;
    text-decoration: none;
    color: $white;
    display: block;
    padding: $paddingY 0.3em;
    padding: $paddingY 0.3em calc(#{$paddingY} - #{$borderWidth}) 0.3em;
    // box-sizing: border-box;
    border-bottom: $borderWidth solid rgba(100,100,100,0);
    &:hover, &.active {
      border-bottom: $borderWidth solid $white;
    }
  }

  .page-content {
    max-width: 600px;
    margin: 1em auto;
    h1 {
      font-weight: 600;
      font-size: 1.5em;
      font-family: $deco;
    }
  }

  .full-menu-link-wrapper {
    padding: 1em 0;
    text-align: center;
    a.button {
      background: $pink;
      color: $white;
    }
  }

  .mag-glass-svg {
    display: none !important;
  }


  .footer {
    font-family: $signatureFont;
    margin-top: 2em;
    // border-top: 4px solid $pink;

    // background: $white;
    // color: black;
    a.phone-link {
      color: $pink;
    }
  }
}
