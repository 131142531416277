@import './_vars.scss';
.cart.minimized {
  .tool-bar {
    display: none;
  }
}
.landing-page {
  // padding-bottom: 100vh;
  font-family: $sigFont;
  // font-weight: 300;
  .splash {
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      text-shadow: 2px 2px 2px black;
      font-size: 2em;
      font-weight: 900;
      color: $orange;
    }
  }
  .splash-image {
    display: block;
    // width: 100%;
    max-width: 300px;
    // margin: 0 auto;
  }

  .hero-wrapper {
    display: flex;
    align-items: flex-end;
  }


  svg.hero-logo {
    display: block;
    max-height: 300px;
  }
  .menu-header {
    max-width: $max-site-width;
    margin: 0 auto;
    text-align: center;
    font-size: 2em;
    padding: 2em 0 1em 0;
    font-family: $deco;
    font-weight: 900;
    // text-transform: uppercase;
  }
  a.order-online-button {
    // margin: 2em 0;
    font-size: 0.8em;
    display: inline-block;
    color: $white;
    text-decoration: none;
    padding: 0.8em 0.8em;
    margin-right: 1.5em;
    margin-bottom: 0.5em;
    background: $orange;
    font-family: $sigFont;
    font-weight: 600;
    box-shadow: none;
    // margin-right: 0.5em;
    // margin: 0.5em;
    // margin-left: 0;
    border-radius: 5px;
    // border: 2px solid #febf3a;
    box-shadow: 1px 1px $darkOrange, 3px 3px $darkOrange, 5px 5px $darkOrange, 7px 7px $darkOrange;
    transition: all 0.12s ease 0s;
    &:hover {
      transform: translate(5px, 5px);
      box-shadow: none
    }
    &.disabled:hover {
      transform: none;
      box-shadow: 1px 1px #ed1e25, 3px 3px #ed1e25, 5px 5px #ed1e25, 7px 7px #ed1e25;
    }
    &.disabled {
      background: gray;
      box-shadow: 1px 1px #ed1e25, 3px 3px #ed1e25, 5px 5px #ed1e25, 7px 7px #ed1e25;
    }
  }
  .logo-banner {
    padding-left: 0.25em;
    padding-right: 0.25em;
    width: 30%;

  }
  .hero-banner {
    width: 70%;
    padding: 1em 0;
    // background-color: red;
    min-height: 400px;
    background: url(https://afag.imgix.net/pablitos-world/logo.png?w=900&auto=format) no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    text-shadow: 1px 1px 1px solid white;
    margin-bottom: 0em;
    border-radius: 0 0 1em 0;
    overflow: hidden;
  }
  // @media screen and (max-width: 500px) {
  //   .logo-banner {
  //     display: none;
  //   }
  //   .hero-banner {
  //     width: 100%;
  //     border-radius: 0;
  //   }
  // }
  .header {
    .banner {
      display: block;
      max-width: 720px;
      width: 90%;
      margin: 0 auto;

      // padding: 0 0.5em;
      // position: relative;
      // box-sizing: border-box;
      // margin-top: -20%;
    }
  }
  .landing-page .content {
    min-height: 100vh;
  }
  .content-grid.sub-header {
    // max-width: none;
    margin: 0 auto;
  }
  .header-text {
    margin-top: 0.8em;
    text-align: center;
    text-transform: uppercase;
    grid-column: 2/5;
    h2 {
      color: $yellow;
      font-weight: 600;
    }
    p {
      font-size: 0.8em;
    }
  }
  .header-info {
    margin-top: 0.8em;
    grid-column: 2/5;
    text-align: center;
    background: $yellow;
    color: white;
    font-weight: 600;
    padding: 0em 0.8em;

    .hours {
      text-transform: uppercase;
      font-size: 0.8em;
    }
    a, a:visited {
      color: white;
    }
  }
  .easter {
    // padding: 0em 0.1em;
    // background: #a3e5f3;
    .links {
      margin: 2em 0;
      a {
        display: block;
        text-decoration: none;
        margin: 0.5em 0;
        // text-decoration-thickness: 1px;
        font-size: 2em;
        h3 {
          line-height: 1em;
          font-weight: 200;
        }
        &:hover {
          h3 {
            font-weight: 900;
          }
        }
      }
    }
    padding: 1em 0;
    .special {
      display: flex;
      .figure {
        max-width: 35%;
        img {
          width: 100%;
        }
      }
      .text {
        padding: 0em 0 0 0.5em;
      }
      margin-bottom: 1em;
    }
    h2 {
      font-family: effra, sans-serif;
      padding: 0.5em 0;
      // background: $yellow;
      color: $yellow;

      font-size: 2em;
      margin-bottom: 1em;
      text-transform: uppercase;
      // padding: 0.5em 0;
      font-weight: 800
    }
    h3 {
      text-transform: uppercase;
      // color: $yellow;
      font-weight: 800;
      font-size: 1.5em;
      line-height: 1.5em;
    }
    a {
      font-size: 1.2em;
      color: $yellow;
      font-weight: 600;
      text-transform: uppercase;
    }
    font-size: 0.6em;
    // text-align: center;
    line-height: 1.3em;
    margin-top: 0.8em;
    grid-column: 2/5;
    font-weight: 800;
  }
  a.order-button {
    display: inline-block;
    // background: $yellow;
    padding: 0.2em 0.3em;

    border-radius: 0.3em;
    // color: white;
    // text-decoration: none;
    margin-right:  0.4em;
  }
  .beverage-menu-link-wrapper {
    max-width: $max-site-width;
    margin: 0 auto;
  }


  .steps-wrapper.content-grid {
    margin-top: 0;
    line-height: 1.1em;
    grid-template-columns: 6% 38% 28% 22% 6%;
    grid-template-rows: auto;

    .step-block {
      padding: 1em 0.2em;
      border-bottom: 2px solid #8c8f98;
      &.one {
        grid-column: 2/3;
      }
      &.two {
        grid-column: 3/4;
      }
      &.three {
        grid-column: 4/5;
      }
    }
    .order-types {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      p {
        flex: 1;
        font-size: 0.8em;
        text-align: left;
      }
    }

    @media screen and (max-width: 650px) {
      .order-types {
        flex-direction: row;
        p {
          text-align: center;
        }
      }
      .step-block.one, .step-block.two, .step-block.three {
        grid-column: 2/5;
      }
    }
    .content-block {
      max-width: 450px;
    }
    .step-numeric {
      font-size: 1.2em;
      font-weight: 600;
      color: #fd7d0a;
    }
    .description {
      margin: 0.5em 0;
      color: #fd7d0a;

      font-style: italic;
    }
    .menu {
      margin: 0.8em 0;

    }
    a, a:visited {
      font-size: 0.9em;
      font-weight: 600;
      text-transform: uppercase;
      color: #900f28;
    }
    .instructions, a.instructions {
      font-weight: 400;
      text-transform: none;
      color: #505353;
      font-size: 0.75em;
      font-style: italic;
    }


  }

  .content-grid {
    display: grid;
    grid-template-columns: 6% 40% 8% 40% 6%;
    grid-template-rows: auto;
    max-width: 950px;
    margin: 2em auto;
  }
  .walnut {
    grid-column: 2/3;
    grid-row: 1/2;
  }
  .oak {
    grid-column: 4/5;
    grid-row: 1/2;
  }
  @media screen and (max-width: 400px) {
    .walnut {
      grid-column: 2/5;
      grid-row: 1/2;
    }
    .oak {
      grid-column: 2/5;
      grid-row: 2/3;
    }
  }
  .email-form {
    grid-column: 2/4;
    grid-row: 3/4;
    margin-top: 3em;
  }
  .social-links {
    grid-column: 2/4;
    grid-row: 4/5;
    padding: 2em 0;
  }
  a.social-link {
    display: flex;
    align-items: center;
    font-weight: 400;
    text-decoration: none;
    color: black;
    margin-bottom: 0.4em;
    svg {
      width: 2em;
      margin-right: 0.8em;
    }
  }

  .locations {
    font-size: 0.7em;
    line-height: 1.3em;
    // display: flex;
    // justify-content: center;
    // margin: 2.5em 0;
    // flex-wrap: wrap;
    .location {
      margin-top: 0em;
      // padding: 0.5em 1em;
      // width: 40%;
      // max-width: 290px;
      // @media(max-width: 590px) {
      //   width: 100%;
      // }
      &.oak {
        // opacity: 0.5;
      }
    }
    .location-name {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.3em;
      // color: $yellow;
      letter-spacing: 0.15em;
      // font-size:
    }
    .subtitle {
      font-weight: 400;
      font-style: italic;
    }
    a.address-link, a.address-link:visited {
      // color: $yellow;
    }
    .menu-links, .order-links {
      a {
        color: $yellow;
        font-weight: 600;
        text-decoration: none;
        display: block;
        line-height: 1.75em;
      }
    }
    .order-links {
      // display: flex;
      // flex-direction: column;
    }
    .order-links a {
      background: $yellow;
      display: inline-block;
      padding: 0.1em 0.4em;
      border-radius: 0.2em;
      margin-bottom: 0.3em;
      color: white;
    }
    .hours-title {
      font-weight: 600;
    }
    .days {
      font-weight: 600;
    }
    .menu-links, .hours, .order-links {
      margin-top: 1em;
    }
  }
  .landing-page-footer {
    // position: fixed;
    width: 100%;
    bottom: 0;
    border-bottom: 1em solid black;
    text-align: right;
    .stout-footer-logo {
      width: 200px;
      display: inline-block;
      // margin: 0 auto;
      // text-align: right;
      // position: fixed;
      // bottom: -2px;
      // right: 0;
      margin-bottom: -32px;
      // z-index: 1
    }
  }
}

#mc_embed_signup {
  font-size: 0.7em;
  .email-label {
    color: $yellow;
    font-size: 1.4em;
    font-weight: 300;
    padding: 0.5em 0;
    margin-bottom: 0.3em;

  }
  .mc-field-group.email {
    display: flex;
    align-items: stretch;
    input.email  {
      flex: 1;
    }
  }
  input {
    font: inherit;
    border-color: $yellow;
    display: inline-block;
    // height: 2em;
    line-height: 1.2em;
    box-sizing: border-box;
    margin-right: 0.3em;
    border-radius: 1px;
  }
  input[type="submit"] {
    box-sizing: border-box;
    margin: 0 !important;

    padding: 0.6em 1.5em;
    display: block;
    box-shadow: none;
    background: $yellow;
    color: white;
    font-weight: 600;

  }
}

.footer {
  display: none !important;

}
